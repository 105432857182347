import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo.png'

export default function Header(props) {

  const handleUnits = (e) => props.setUnits(e.target.value)


  return (
    <header>
      {/*<nav>*/}
      {/*  <ul>*/}
      {/*    <li><Link to="/">Home</Link></li>*/}
      {/*    <li><Link to="/faq">FAQ</Link></li>*/}
      {/*  </ul>*/}
      {/*</nav>*/}
      <div className='bar'>
        <h1 className='logo'>MeasureMaps.com</h1>
        <h3 className='description'>Measure Area and Distance on map</h3>
        <Link to="/faq">FAQ</Link>
        {/*<form onChange={handleUnits} className="units-form">*/}
        {/*  <select name='units'>*/}
        {/*    <option value='metric'>Metric</option>*/}
        {/*    <option value='imperial'>Imperial</option>*/}
        {/*  </select>*/}
        {/*</form>*/}
      </div>
    </header>
  )
}
