const initialState = {
  id: null,
  email: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGIN': {
      const { id, email } = action.payload;
      return {
        ...state,
        id,
        email,
      };
    }

    default:
      return state;
  }
}
