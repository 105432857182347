import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Map from './Map.jsx';
import Header from './Header.jsx';
import ReactGA from "react-ga4";
import faq from './faq.jsx';
import './styles.css';
import './leaflet.measure.css';
import './areaMeasure.css';
import './leaflet.measure.js';
import './Leaflet.PolylineMeasure.js';
import './Leaflet.PolylineMeasure.css';

export default function App() {
  ReactGA.initialize([{
    trackingId: "G-K1LMYLQJTF",
  }]);
  const [latLng, setLatLng] = useState([33.436476, -38.031505]);
  const [units, setUnits] = useState('metric');

  useEffect(() => {
    getIP();
  }, [])


  const getIP = () => {
    fetch(`https://ipapi.co/json/`)
      .then(res => res.json())
      .then(data => setLatLng([data.latitude, data.longitude]))
  }

  return(
    <Router>
      <Header setUnits={setUnits} units={units} />
      <Switch>
        <Route exact path="/" render={() => <Map coords={latLng} units={units} />} />

        <Route path="/faq" component={faq} />
      </Switch>
    </Router>
    )
}
