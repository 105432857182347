// -- Import dependencies --
import React from 'react';
import {Map} from 'react-leaflet';
import { LocationIQProvider, GeoSearchControl } from 'leaflet-geosearch';
// import 'leaflet-geosearch/dist/geosearch.css'
import { distanceOptions } from "./utils";
import * as locate from 'leaflet.locatecontrol';
import * as lbtn from 'leaflet-easybutton';
import Tangram from 'tangram';
import 'leaflet/dist/leaflet.css';
import ReactGA from "react-ga4";



const provider = new LocationIQProvider({
  params: {
    key: '153674d8ec5d0b',
  },
});

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;



export default class LeafletMap extends React.Component {

  constructor(props) {
    super(props);
    this.mm = React.createRef();
    this.dm = React.createRef();
    this.getPrimaryAreaUnit = this.getPrimaryAreaUnit.bind(this)
    this.getSecondaryAreaUnit = this.getSecondaryAreaUnit.bind(this)
    this.getPrimaryLengthUnits = this.getPrimaryLengthUnits.bind(this)
    this.getSecondaryLengthUnits = this.getSecondaryLengthUnits.bind(this)
    this.getDistanceUnits = this.getDistanceUnits.bind(this)
  }
  componentDidMount() {

    L.Map.addInitHook(function () {
      mapsPlaceholder.push(this); // Use whatever global scope variable you like.
    });

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map.leafletElement);

    // layer.addTo(this.map.leafletElement);

    const areaOptions = {
      units: "miles",
      position: 'topleft',
      primaryLengthUnit: this.getPrimaryLengthUnits(),
      secondaryLengthUnit: this.getSecondaryLengthUnits(),
      primaryAreaUnit: this.getPrimaryAreaUnit(),
      secondaryAreaUnit: this.getSecondaryAreaUnit(),
      activeColor: 'orange',
      completedColor: 'blue',
      color: '#f4a003'
    }
    this.mm.current = new L.Control.Measure(areaOptions);
    this.mm.current.addTo(this.map.leafletElement);
    this.dm.current = L.control.polylineMeasure(distanceOptions);
    this.dm.current.addTo(this.map.leafletElement);

    L.control.locate({ position: 'topright' }).addTo(this.map.leafletElement);
    L.control.zoom({position: 'topright'}).addTo(this.map.leafletElement);

    this.map.leafletElement.addControl(
      new GeoSearchControl({
        provider,
        style: 'bar',
        marker: DefaultIcon
      }),
    );

    this.map.leafletElement.on('polylinemeasure:toggle', (e) => console.info(e))
    this.map.leafletElement.on('polylinemeasure:start', (current) => {
      console.info('start', current)
      ReactGA.event({
        category: 'Distance',
        action: 'Start',
      });
    })
    this.map.leafletElement.on('polylinemeasure:resume', (current) => console.info('resume', current))
    this.map.leafletElement.on('polylinemeasure:finish', (current) => {
      console.log('finsih')
      ReactGA.event({
        category: 'Distance',
        action: 'Finish',
      });
    })
    this.map.leafletElement.on('polylinemeasure:clear', (current) => console.info('clear', current))
    this.map.leafletElement.on('polylinemeasure:add', (current) => {
      console.info('add', current)
      ReactGA.set({
        category: 'Distance',
        action: 'Add',
        label:  `${current.latlng.lat}, ${current.latlng.lng}`
      });
    })
    this.map.leafletElement.on('polylinemeasure:insert', (current) => console.info('insert', current))
    this.map.leafletElement.on('polylinemeasure:move', (current) => console.info('move', current))
    this.map.leafletElement.on('polylinemeasure:remove', (current) => console.info('remove', current))

    this.map.leafletElement.on('measurestart', (e) => console.log(e))
    this.map.leafletElement.on('measurefinish', (e) => console.log('finish', e))

    setTimeout(() => {
      this.map.leafletElement.fireEvent('areameasure')
    }, 1000);
  }

  getPrimaryLengthUnits = () => {
    if(this.props.units === 'metric') {
      return 'meters'
    } else {
      return 'feet'
    }
  }

  getSecondaryLengthUnits = () => {
    if(this.props.units === 'metric') {
      return 'kilometers'
    } else {
      return 'miles'
    }
  }

  getPrimaryAreaUnit = () => {
    if(this.props.units === 'metric') {
      return 'meters'
    } else {
      return 'feet'
    }
  }

  getSecondaryAreaUnit = () => {
    if(this.props.units === 'metric') {
      return 'hectares'
    } else {
      return 'sqmiles'
    }
  }

  getDistanceUnits = () => {
    if(this.props.units === 'metric') {
      return 'metres'
    } else {
      return 'landmiles'
    }
  }

  componentDidUpdate(nextProps) {
    console.log('nextProps', nextProps);
    if(this.props.units !== nextProps.units) {
      console.log('changed');
      console.log('this.dm.current.options', this.dm.current.options);
      this.dm.current.options.unit = this.getDistanceUnits()
      this.mm.current.options.primaryAreaUnit = this.getPrimaryLengthUnits()
      this.mm.current.options.secondaryAreaUnit = this.getSecondaryAreaUnit()
      this.mm.current.options.primaryLengthUnit = this.getPrimaryLengthUnits()
      this.mm.current.options.secondaryLengthUnit = this.getSecondaryLengthUnits()
    }
    if(this.props.coords !== nextProps.coords) {
      this.map.leafletElement.setView(this.props.coords, 9)
    }

  }


  render() {
    return (
      <Map zoom={3} center={[33.436476, -38.031505]} zoomControl={false} ref={(ref) => {this.map = ref}}/>
    );
  }
};
