import React from 'react';
import { Link } from 'react-router-dom';


const faq = () => {
  return (
    <div className="page-container">
      <div>
        <Link to={'/'}>← Back to map</Link>
        <h1>Frequently Asked Questions</h1>
        <p>Here you can find answers to the most common questions.</p>
        <h2>What does map distance measurement do?</h2>
        <p>It allows users to measure distances of simple lines and complex polylines on a map, considering the
          curvature of the Earth and using the Great-circle distance method.</p>
        <h2>How are the arrows placed on the distance lines?</h2>
        <p>Arrows are placed to indicate the real midways of the lines’ Great-circle distances, not the optical middle,
          which can appear distorted due to map projections, especially at higher latitudes.</p>
        <h2>How do I finish drawing a distance line?</h2>
        <p>You can finish a line by:
          <ul>
            <li>Double-clicking</li>
            <li>Single-clicking on the last point (indicated in orange)</li>
            <li>Pressing the “ESC” key</li>
          </ul>

        </p>
        <h2>Can I move the points after drawing the distance line?</h2>
        <p>Yes, you can move points by clicking and dragging them. However, this feature might not work on every mobile
          browser, particularly Chrome Mobile, where issues have been reported.</p>
        <h2>How do I continue drawing a finished distance line?</h2>
        <p>To extend a finished line, hold the Ctrl key and click on either the first or last point of the line.</p>
        <h2>How can I add new points to a distance line?</h2>
        <p>To add points to a line, hold the Ctrl key and click on an arrow along the line.
        </p>
        <h2>How can I delete points from a distance line?</h2>
        <p>To delete points, hold the Shift key and click on the point you want to remove.</p>
      </div>
    </div>
  );
};

export default faq;
